import { LoadingButton } from "@mui/lab";
import { Button, Select, MenuItem } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useEffect, useState } from 'react';
import { IMaskInput } from "react-imask";

import './index.css';
import { api } from "../../lib/api";
import customToast from "../../toast/customToast";

interface IUser {
    CNPJCPF: string;
    Nome: string;
    Ativo: string;
    CEP: string;
    Endereco: string;
    Numero: string;
    Complemento?: string;
    Bairro: string;
    Cidade: string;
    UF: string;
    Tel1: string;
    Tel2?: string;
    idEmpresa: number;
    idcidade: number;
    ex: number;
}

interface IFreteBairro {
    Id: number;
    CEP: string;
    Cidade: string;
    UF: string;
    Bairro: string;
}

interface NewClientProps {
    cpfProps?: string;
    phoneProps?: string;
    loadClient: (cpf: string) => void;
}

export function EditClientComponent({ phoneProps, cpfProps, loadClient }: NewClientProps) {

    const [id, setId] = useState<number | null>(null);
    const [cpf, setCpf] = useState('');
    const [name, setName] = useState('');
    const [phoneOne, setPhoneOne] = useState('');
    const [phoneTwo, setPhoneTwo] = useState('');
    const [cep, setCep] = useState('');
    const [address, setAddress] = useState('');
    const [number, setNumber] = useState('');
    const [district, setDistrict] = useState('');
    const [districtText, setDistrictText] = useState('');
    const [city, setCity] = useState('');
    const [uf, setUf] = useState('');
    const [complemento, setComplemento] = useState('');
    const [cityId, setCityId] = useState('');

    const [districtList, setDistrictList] = useState<IFreteBairro[]>([]);


    const [open, setOpen] = useState(false);

    const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

    useEffect(() => {
        if (phoneProps) {
            loadClienteByPhone(phoneProps);
        }
        if (cpfProps) {
            loadClienteByCpf(cpfProps);
        }
    }, [])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function loadClienteByCpf(cpf: string) {

        cpf = removeMask(cpf);

        try {
            const result = await api.get(`/client/document/${cpf}`);

            await loadBairrosByCep(result.data.CEP, result.data.Bairro);

            setId(result.data.Id);
            setCity(result.data.Cidade ? result.data.Cidade : '');
            setUf(result.data.UF ? result.data.UF : '');
            // setDistrict(result.data.Bairro ? result.data.Bairro : '');
            setAddress(result.data.Endereco ? result.data.Endereco : '');
            setCityId(result.data.idcidade ? result.data.idcidade : '');
            setCpf(result.data.CNPJCPF ? result.data.CNPJCPF : '');
            setName(result.data.Nome ? result.data.Nome : '');
            setCep(result.data.CEP ? result.data.CEP : '');
            setNumber(result.data.Numero ? result.data.Numero : '');
            setComplemento(result.data.Complemento ? result.data.Complemento : '');
            setPhoneOne(result.data.Tel1 ? result.data.Tel1 : '');
            setPhoneTwo(result.data.Tel2 ? result.data.Tel2 : '');
        } catch (err) {
            console.log(err);
        }
    }

    async function loadClienteByPhone(phone: string) {

        phone = removeMask(phone);

        try {
            const result = await api.get(`/client/phone/${phone}`);

            await loadBairrosByCep(result.data.CEP, result.data.Bairro);

            setId(result.data.Id);
            setCity(result.data.Cidade ? result.data.Cidade : '');
            setUf(result.data.UF ? result.data.UF : '');
            // setDistrict(result.data.Bairro ? result.data.Bairro : '');
            setAddress(result.data.Endereco ? result.data.Endereco : '');
            setCityId(result.data.idcidade ? result.data.idcidade : '');
            setCpf(result.data.CNPJCPF ? result.data.CNPJCPF : '');
            setName(result.data.Nome ? result.data.Nome : '');
            setCep(result.data.CEP ? result.data.CEP : '');
            setNumber(result.data.Numero ? result.data.Numero : '');
            setComplemento(result.data.Complemento ? result.data.Complemento : '');
            setPhoneOne(result.data.Tel1 ? result.data.Tel1 : '');
            setPhoneTwo(result.data.Tel2 ? result.data.Tel2 : '');
        } catch (err) {
            console.log(err);
        }
    }

    async function loadBairrosByCep(cep: string, clientBairro: string) {
        var result = await api.get(`/district-freight/${cep}`);

        var bairros: IFreteBairro[] = result.data;

        setDistrictList(bairros);

        if (bairros.length > 0) {
            let item = bairros.find(value => value.Bairro === clientBairro);

            if (item) {
                setDistrict(clientBairro);
            }
            else {
                setDistrict("0");
                setDistrictText(clientBairro);
            }
        }
        else {
            setDistrict(clientBairro);
        }



    }

    async function changeCep(value: string) {
        setCep(value);

        value = removeMask(value);

        if (value.length === 8) {
            try {
                const result = await axios.get(`https://viacep.com.br/ws/${value}/json/`);

                if (result.data.erro) {
                    customToast.error("CEP inválido");
                    setCity("");
                    setUf("");
                    return;
                }

                setCity(result.data.localidade);
                setUf(result.data.uf);
                result.data.bairro != "" && setDistrict(result.data.bairro);
                result.data.logradouro != "" && setAddress(result.data.logradouro);
                result.data.ibge != "" && setCityId(result.data.ibge);

                var bairros = await api.get(`/district-freight/${value}`);

                setDistrictList(bairros.data);

                if (bairros.data.length > 0) {
                    setDistrict(bairros.data[0].Bairro);
                }
                else {
                    setDistrict(result.data.bairro);
                }

            } catch (err) {
                console.log(err);
            }
        } else {
            setCity('');
            setUf('');
            setAddress('');
            setCityId('');
            setDistrict('');
            setDistrictList([]);
        }
    }

    function removeMask(value: string) {
        return value.replaceAll('/', '').replaceAll('.', '').replaceAll('-', '').replaceAll('(', '').replaceAll(')', '');
    }

    async function onSubmit() {

        if (id === null) {
            customToast.error("Cliente inválido");
            return;
        }

        if (name.length < 1) {
            customToast.error("O campo nome é obrigatório");
            return;
        }

        if (cep.length < 8) {
            customToast.error("O campo CEP é obrigatório");
            return;
        }

        if (address.length < 4) {
            customToast.error("O campo endereço deve conter ao menos 4 caracteres");
            return;
        }

        if (city.length < 1) {
            customToast.error("O campo cidade é obrigatório. Informe um CEP válido");
            return;
        }

        if (district.length < 1 && district !== "0") {
            customToast.error("O campo bairro é obrigatório");
            return;
        }

        if (districtList.length > 0 && district === "0" && districtText.length < 1) {
            customToast.error("O campo bairro é obrigatório");
            return;
        }

        if (uf.length < 2) {
            customToast.error("O campo UF é obrigatório. Informe um CEP válido");
            return;
        }

        if (removeMask(phoneOne).length < 10) {
            customToast.error("Telefone 1 inválido");
            return;
        }

        if (removeMask(phoneTwo).length > 0 && removeMask(phoneTwo).length < 10) {
            customToast.error("Telefone 2 inválido");
            return;
        }

        const data: IUser = {
            Nome: name,
            CNPJCPF: cpf,
            CEP: removeMask(cep),
            Endereco: address,
            Numero: number,
            UF: uf,
            Bairro: districtList.length > 0 && district === "0" ? districtText : district,
            Cidade: city,
            idEmpresa: 4,
            Tel1: removeMask(phoneOne),
            Tel2: phoneTwo.length > 0 ? removeMask(phoneTwo) : undefined,
            Complemento: complemento.length > 0 ? complemento : undefined,
            idcidade: Number(cityId),
            Ativo: 'S',
            ex: 0
        }

        setIsLoadingData(true);

        try {
            const result = await api.put(`/client/${id}`, data);

            customToast.success("Atualizado com sucesso");

            loadClient(result.data.CNPJCPF);
            handleClose();

        } catch (err: any) {
            console.log(err);
            if (err.response.data.message) {
                customToast.error(err.response.data.message);
            }
            else {
                customToast.error("Ocorreu um erro interno");
            }
        } finally {
            setIsLoadingData(false);
        }
    }

    return (
        <>
            <Button sx={{ backgroundColor: '#f39c12', ':hover': { backgroundColor: '#e67e22' } }} onClick={handleClickOpen} variant="contained" style={{ height: '54px' }}> <i className="fa-solid fa-pen-ruler"></i> </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    <div className='product-modal-group modal-group-image-title'>
                        Atualizar dados
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className="new-client-modal-content">
                        <div className="new-client-input-group">
                            <label>CPF</label>
                            <IMaskInput label="CPF" mask="000.000.000-00" onChange={() => ''} value={cpf} onAccept={value => setCpf(`${value}`)} disabled />
                        </div>
                        <FormControl fullWidth>
                            <label className="new-client-input-label">Nome</label>
                            <TextField type="text" variant="outlined" value={name} onChange={e => setName(e.target.value)} />
                        </FormControl>
                        <div className="new-client-input-group">
                            <label>Telefone 1</label>
                            <IMaskInput label="Telefone 1" mask="(00)0000[0]-0000" onChange={() => ''} value={phoneOne} onAccept={value => setPhoneOne(`${value}`)} />
                        </div>
                        <div className="new-client-input-group">
                            <label>Telefone 2</label>
                            <IMaskInput label="Telefone 2" mask="(00)0000[0]-0000" onChange={() => ''} value={phoneTwo} onAccept={value => setPhoneTwo(`${value}`)} />
                        </div>
                        <div className="new-client-input-group">
                            <label>CEP</label>
                            <IMaskInput label="CEP" mask="00000-000" onChange={() => ''} value={cep} onAccept={value => changeCep(`${value}`)} />
                        </div>
                        <div className="wrap-box">
                            <FormControl fullWidth>
                                <label className="new-client-input-label">Cidade</label>
                                <TextField type="text" variant="outlined" value={city} disabled />
                            </FormControl>
                            <FormControl fullWidth>
                                <label className="new-client-input-label">UF</label>
                                <TextField type="text" variant="outlined" value={uf} disabled />
                            </FormControl>
                        </div>

                        {
                            districtList.length > 0 ?
                                <>
                                    <label className="new-client-input-label">Bairro</label>
                                    <Select
                                        value={district}
                                        label="Bairro"
                                        onChange={e => setDistrict(e.target.value)}
                                    >
                                        <MenuItem value="0">Outro</MenuItem>
                                        {
                                            districtList.map(district => {
                                                return (
                                                    <MenuItem key={district.Id} value={district.Bairro}>{district.Bairro}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </>

                                :
                                <FormControl fullWidth>
                                    <label className="new-client-input-label">Bairro</label>
                                    <TextField type="text" variant="outlined" value={district} onChange={e => setDistrict(e.target.value)} />
                                </FormControl>
                        }

                        {
                            districtList.length > 0 && district === "0" &&
                            <FormControl fullWidth>
                                <TextField type="text" label="Informe o nome do bairro" value={districtText} variant="outlined" onChange={e => setDistrictText(e.target.value)} />
                            </FormControl>
                        }

                        <div className="wrap-box">
                            <FormControl fullWidth>
                                <label className="new-client-input-label">Endereço</label>
                                <TextField type="text" variant="outlined" value={address} onChange={e => setAddress(e.target.value)} />
                            </FormControl>
                            <FormControl fullWidth>
                                <label className="new-client-input-label">Número</label>
                                <TextField type="text" variant="outlined" value={number} onChange={e => setNumber(e.target.value)} />
                            </FormControl>
                        </div>
                        <FormControl fullWidth>
                            <label className="new-client-input-label">Complemento</label>
                            <TextField type="text" variant="outlined" value={complemento} onChange={e => setComplemento(e.target.value)} />
                        </FormControl>

                    </div>

                </DialogContent>
                <DialogActions>
                    <LoadingButton onClick={handleClose} className="default-button">Cancelar</LoadingButton>
                    <LoadingButton loading={isLoadingData} className="default-button add-button" onClick={() => onSubmit()}>
                        Salvar
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>

    )
}