import { useState, useEffect } from 'react';
import { IMaskInput } from "react-imask";

import { ptBR } from '@mui/x-date-pickers/locales';
import { ptBR as ptBRfns } from 'date-fns/locale';

import './Orders.css';
import { api } from '../../lib/api';
import customToast from '../../toast/customToast';
import OrderCardComponent from '../../components/OrderCard/OrderCardComponent';
import { CircularProgress } from '@mui/material';
import { useCart } from '../../hook/useCart';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import { IOrder } from '../../interfaces/IOrder';



export default function Orders() {

    // const [cpf, setCpf] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());

    const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

    const [orders, setOrders] = useState<IOrder[]>([]);

    const { userOrder, setUserOrder } = useCart();

    useEffect(() => {

        if (userOrder.length === 11) {
            changeCpf(userOrder);
        }
    }, [])

    async function changeCpf(value: string) {

        setUserOrder(value);

        if (value.length == 11) {
            setIsLoadingData(true);
            try {
                const res = await api.get(`/client/document/${value}/orders`);

                setName(res.data.Nome);
                setCity(res.data.Cidade);

                changeDate(startDate, endDate, value);

            } catch (err) {
                setName('');
                setCity('');
                customToast.error("Cliente não encontrado");
            } finally {
                setIsLoadingData(false);
            }
        }
        else {
            setName('');
            setCity('');
            setOrders([]);
        }
    }

    function removeMask(value: string) {
        return value.replaceAll('/', '').replaceAll('.', '').replaceAll('-', '').replaceAll('(', '').replaceAll(')', '');
    }

    async function changeDate(startDate: Date, endDate: Date, cpf: string) {

        setStartDate(startDate);
        setEndDate(endDate);

        var formatedStartDate = format(startDate, 'yyyy-MM-dd');
        var formatedEndDate = format(endDate, 'yyyy-MM-dd');

        setIsLoadingData(true);

        try {
            const result = await api.get(`/order/client/${cpf}/start-date/${formatedStartDate}/end-date/${formatedEndDate}`);

            var items: IOrder[] = result.data;

            setOrders(items);
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoadingData(false);
        }
    }

    return (
        <div className="orders-page">
            <div className="orders-page-inputs">
                <div className="input-group">
                    <label>CPF</label>
                    <IMaskInput label="CPF" mask="000.000.000-00" defaultValue={userOrder} onChange={() => { }} onAccept={value => changeCpf(removeMask(`${value}`))} />
                </div>
                <div className='input-group'>
                    <label>Nome</label>
                    <input value={name} disabled />
                </div>
                <div className='input-group'>
                    <label>Cidade</label>
                    <input value={city} disabled />
                </div>
            </div>
            <div className='orders-page-items'>
                <div className='orders-page-items-filter'>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRfns} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
                        <div className='orders-page-items-filter-group'>
                            <label> <i className="fa-solid fa-calendar-day"></i> Data inicial</label>
                            <DatePicker
                                format='dd/MM/yyyy'
                                closeOnSelect
                                disableFuture
                                value={startDate}
                                maxDate={endDate}
                                onChange={(value) => changeDate(value as Date, endDate, userOrder)
                                } />
                        </div>
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRfns} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
                        <div className='orders-page-items-filter-group'>
                            <label> <i className="fa-solid fa-calendar-days"></i> Data final</label>
                            <DatePicker
                                format='dd/MM/yyyy'
                                closeOnSelect
                                disableFuture
                                value={endDate}
                                minDate={startDate}
                                onChange={(value) => (setEndDate(value as Date), changeDate(startDate, value as Date, userOrder))
                                } />
                        </div>
                    </LocalizationProvider>
                </div>
                <div className='orders-page-items-title'>
                    {orders.length} pedido(s) encontrados
                </div>
                {
                    isLoadingData ?
                        <CircularProgress sx={{ display: 'flex', margin: '0 auto' }} />
                        :
                        <div className='orders-page-items-content'>
                            {
                                orders.map(order => {
                                    return (
                                        <OrderCardComponent key={order.Id} order={order} />
                                    )
                                })
                            }
                        </div>
                }

            </div>
        </div>
    )
}