import { ReactNode, useEffect } from "react";
import { useState, createContext } from 'react';
import { api } from "../lib/api";

export interface CompanyProps {
    Id: number;
    Razao: string;
    Fantasia: string;
    CNPJCPF: string;
    Cidade: string;
    UF: string;
    Status: string;
    Logo: string;
    Email: string;
    Tema: string;
    Endereco: string;
    CEP: string;
    Aberto: string;
    NomeCombo: string;
    HorarioMinimoRetirada?: string;
    HorarioMaximoRetirada?: string;
    MensagemFinalPedido?: string;
    MensagemWhatsapp?: string;
    CampoMensagemWhatsapp?: string;
    NumeroWhatsapp?: string;
    corPrimaria?: string;
    corSecundaria?: string;
    corTerciaria?: string;
}

export interface CompanyContextDataProps {
    company: CompanyProps | null;
    isLoadingCompany: boolean;
    loadCompany: (id: number) => Promise<void>;
}

interface CompanyProviderProps {
    children: ReactNode;
}

export const CompanyContext = createContext({} as CompanyContextDataProps);

export function CompanyContextProvider({ children }: CompanyProviderProps) {
    const [company, setCompany] = useState<CompanyProps | null>(null);
    const [isLoadingCompany, setIsLoadingCompany] = useState<boolean>(true);

    useEffect(() => {
        loadCompany();
    }, [])

    async function loadCompany() {
        setIsLoadingCompany(true);

        try {
            const res = await api.get(`/company/4`);

            setCompany(res.data);
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoadingCompany(false);
        }
    }


    return (
        <CompanyContext.Provider
            value={{
                company,
                isLoadingCompany,
                loadCompany
            }}
        >
            {children}
        </CompanyContext.Provider>
    )

}