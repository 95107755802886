import './index.css'
import KaelexLogo from '../../assets/kaelex-logo.png';
import { Link } from 'react-router-dom';

export default function NotFound() {
    return (
        <div className='not-found-page'>
            <div className='not-found-page-logo'>
                <img src={KaelexLogo} alt="" />
            </div>
            <div className='not-found-page-text'>
                <div>Página não encontrada</div>
                <Link to="/">Clique aqui para acessar</Link>
            </div>
        </div>
    )
}